import React, { Fragment, useMemo, useState } from 'react';
import styles from './Degrees.module.scss';
import Carousel from '../../../molecule/carousel/Carousel';
import { CarouselData } from './degreesCarousel/CarouselData';

const SECTION_ID = 3;

const Degrees = ({ currentSection }) => {
  const [startX, setStartX] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);

  const [endX, setEndX] = useState(0);
  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    setEndX(e.changedTouches[0].clientX);
  };

  const swipeAction = endX - startX;

  const getSlide = (slide) => {
    setCurrentSlide(slide);
  };
  return (
    <div
      className={styles['degrees-contaner']}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      {/* {currentSlide === 0 ? (
        <img src={bachelorsImage} alt="" className={styles.bachelorsImage} />
      ) : currentSlide === 1 ? (
        <img src={mastersImage} alt="" className={styles.bachelorsImage} />
      ) : (
        <img src={phdImage} alt="" className={styles.bachelorsImage} />
      )} */}

      <div style={{ zIndex: 10 }}>
        <Carousel
          components={CarouselData}
          dots={styles['desktop-dots']}
          swipeAction={swipeAction}
          hideArrowsOnMobile
          getSlide={getSlide}
        />
      </div>
    </div>
  );
};

export default Degrees;
