import React from 'react';
import styles from './SlideThree.module.scss';
import phdBoy from '../../../../../../assets/icons/students-degrees/Phdboy.png';
import phdGirl from '../../../../../../assets/icons/students-degrees/Phdgirl.png';
import { useSelector } from 'react-redux';
import Text from '../../../../../atoms/text/Text';
import degreeStyles from '../../Degrees.module.scss';
import MajorCard from '../../../../../molecule/bachelorCard/MajorCard';
import employee from '../../../../../../assets/icons/employee.svg';
import { useTranslation } from 'react-i18next';

const SlideThree = () => {
  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const degreeData = useSelector((state) => state?.degreeData?.degreeData);
  const phdDegree = degreeData?.items
    ?.filter((item) => item?.degreeType === 2)
    .slice(0, 8);
  return (
    <div className={styles['slide-three']}>
      {/* <img className={degreeStyles.student1} src={phdBoy} loading="lazy" />
      <img className={degreeStyles.student2} src={phdGirl} loading="lazy" /> */}
      <div className={styles['content']}>
        <Text
          title="home.phd_degree"
          variant="text-bold-italic-50"
          style={{ textTransform: 'uppercase', marginBottom: '50px' }}
          textContainerStyle={{ textAlign: 'center' }}
          isLineBreak
        />
        <div
          className={styles['grid-container']}
          style={{ direction: currentLanguage === 'ar' ? 'rtl' : '' }}
        >
          <div className={styles['grid']}>
            {phdDegree?.map((item) => (
              // <div className={styles.card}>
              <MajorCard
                key={item?.degreeId}
                id={item?.degreeId}
                titleEN={item?.degreeNameEn}
                title={
                  currentLanguage === 'en'
                    ? item?.degreeNameEn
                    : item?.degreeNameAr
                }
                svg={employee}
                isCadet={item.forAllOrCadets}
                className={styles.card}
              />
              // </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideThree;
