/* eslint-disable */
import React, { useState, useEffect } from 'react';
import styles from './CardsSections.module.scss';
import Text from '../../../../../../components/atoms/text/Text';
import facilities from './letterWrapper.svg';
import { useTranslation } from 'react-i18next';
const cardText = [
  {
    letter: '1',
    en: 'Preparing, qualifying, and training students to become officers in the force, holding degrees in Law and Police Sciences.',
    ar: 'إعداد وتأهيل وتدريب الطلبة ليكونوا ضباطا في القوة من حملة الليسانس في الحقوق والشرطة.',
  },
  {
    letter: '2',
    en: "Preparing and qualifying enrolled officers, non-commissioned officers, and personnel to obtain a Bachelor's degree in Law.",
    ar: 'إعداد وتأهيل المنتسبين من الضباط وضباط الصف والأفراد للحصول على شهادة الليسانس في الحقوق. ',
  },
  {
    letter: '3',
    en: 'Preparing and qualifying university graduates from various specializations specified by the General Commander to obtain a Diploma in Police Sciences.',
    ar: 'إعداد وتأهيل حملة المؤهلات الجامعية من التخصصات المختلفة التى يحددها القائد العام للحصول على شهادة الدبلوم في علوم الشرطة.',
  },
  {
    letter: '4',
    en: 'Preparing, qualifying, and training new non-commissioned officers and police personnel according to the latest curricula, introducing them to laws, regulations, systems, and general duties.',
    ar: 'إعداد وتأهيل وتدريب ضباط الصف وأفراد الشرطة المستجدين وفق أحدث المناهج, وتعريفهم بالقوانين واللوائح والنظم والواجبات العامة.',
  },
  {
    letter: '5',
    en: 'Preparing, qualifying, and training selected individuals to work as instructors at the college or training schools affiliated with the force.',
    ar: 'إعداد وتأهيل وتدريب من يقع عليهم الاختيار للعمل كمدربين في الكلية أو مدارس التدريب التابعة للقوة.',
  },
  {
    letter: '6',
    en: "Conducting various types of training courses for force members as determined by the college's regulations.",
    ar: 'عقد الدورات التدريبية بأنواعها المختلفة لمنتسبي القوة وفقا لما تحدده لوائح الكلية. ',
  },
];

const CardsSections = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const isRtl = language === 'ar';
  const [expandedCardIndex, setExpandedCardIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const toggleExpand = (index) => {
    setExpandedCardIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getShortText = (text) => {
    const maxLength = 50;
    return text.length > maxLength
      ? text.substring(0, maxLength) + '...'
      : text;
  };

  return (
    <div
      className={styles['cards-section2']}
      style={{ direction: isRtl ? 'rtl' : 'ltr' }}
    >
      {' '}
      <Text
        title="History.thirteenSection"
        variant="text-regular-20"
        className={styles.title}
      />
      <div
        className={styles['cards-section']}
        style={{ direction: isRtl ? 'rtl' : 'ltr' }}
      >
        <div className={styles['cards-grid']}>
          {cardText.map((card, index) => (
            <div className={styles.card} key={index}>
              <div className={styles.letter}>
                <Text
                  title={card.letter}
                  variant="text-bold-italic-20"
                  color="green"
                  className={styles.text}
                />
                <img src={facilities} alt="" className={styles.image} />
              </div>
              <div className={styles.footer}>
                <Text
                  title={
                    expandedCardIndex === index || !isMobile
                      ? card[language]
                      : getShortText(card[language])
                  }
                  variant="text-regular-20"
                  style={{ lineHeight: 1.5 }}
                />

                {isMobile && expandedCardIndex !== index ? (
                  <Text
                    title={'Major.Read_More'}
                    variant="text-bold-italic-20"
                    className={styles.seeMore}
                    onClick={() => toggleExpand(index)}
                  />
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardsSections;
