import React from 'react';
import styles from './GalleryInfo.module.scss';
import downArrow from '../../../../../../assets/icons/downArrow.svg';
import homeSvg from '../../../../../../assets/icons/homeWhite.svg';
import { AlbumCard } from '../../../../../molecule/mediaCard/MediaCard';
import Text from '../../../../../atoms/text/Text';
import { useTranslation } from 'react-i18next';

import DOMPurify from 'dompurify';

const GalleryInfo = ({ handleSlideChange, data }) => {
  const { i18n } = useTranslation();
  const isRtl = i18n.language !== 'en';
  return (
    <div className={styles['gallery-info']}>
      <div className={`${styles.header} ${isRtl ? 'rtl' : ''}`}>
        <Text
          title={isRtl ? data?.galleryName_AR : data?.galleryName_EN}
          variant="text-bold-italic-30"
          className={styles.text}
        />
        <img src={downArrow} alt="Down Arrow" loading="lazy" />
      </div>
      <div className={styles['album-grid']}>
        {data?.images &&
          data?.images?.map((image, index) => (
            <AlbumCard
              key={index}
              src={DOMPurify.sanitize(image?.url + image?.imageURL)}
              onClick={() => handleSlideChange(1, image?.mediaGalleryId)}
            />
          ))}
      </div>
      <div className={styles.toTop}>
        <Text
          svg={homeSvg}
          title="common.Back_to_top"
          variant="text-bold-italic-30"
          className={styles.text}
          onClick={() => handleSlideChange(0)}
        />
      </div>
    </div>
  );
};

export default GalleryInfo;
