import React, { useEffect } from 'react';
import styles from './AboutTheAcademy.module.scss';
import Text from '../../../atoms/text/Text';
import { ToggleList } from '../../../molecule/ToggleList/ToggleList';
import backgroundImage from '../../../../assets/images/home/about-the-academy/about-the-academy.jpg';
import ScrollReveal from 'scrollreveal';
import ImageWithBackdrop from '../../../molecule/imageWithBackdrop/ImageWithBackdrop';
import { useTranslation } from 'react-i18next';

const AboutTheAcademy = () => {
  const { i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  useEffect(() => {
    ScrollReveal().reveal('#text', {
      delay: 100,
      duration: 500,
      easing: 'ease-in-out',
      opacity: 0,
      scale: 0.9,
      distance: '20px',
      reset: true,
    });
    ScrollReveal().reveal('#toggle >*', {
      delay: 500,
      duration: 500,
      easing: 'ease-in-out',
      opacity: 0,
      scale: 0.9,
      distance: '20px',
      reset: true,
    });
  }, []);
  const toggleData = [
    {
      id: 1,
      title: 'About_The_Academy.Header-Title',
      route: '/about/overview',
    },
    {
      id: 3,
      title: 'About_The_Academy.History',
      route: '/about/history',
    },
    {
      id: 2,
      title: 'About_The_Academy.Team',
      route: '/about/team',
    },

    {
      id: 4,
      title: 'About_The_Academy.Leadership',
      route: '/about/leadership',
    },
  ];

  return (
    <div className={styles['about-the-academy']}>
      <ImageWithBackdrop
        src={backgroundImage}
        alt="about the academy"
        backdropStyle={{ zIndex: -1 }}
        isAnimated
      />
      <Text
        id="text"
        title="home.about_the_academy"
        variant="text-bold-italic-80"
        style={{ textTransform: 'uppercase' }}
        isLineBreak
        className="slide-in"
      />
      <div className={styles['program']}>
        <div
          id="toggle"
          className={styles['toggle-container']}
          style={{ direction: isRtl ? 'rtl' : '' }}
        >
          <ToggleList data={toggleData} isNavigable />
        </div>
      </div>
    </div>
  );
};

export default AboutTheAcademy;
