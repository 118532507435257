import React from 'react';
import styles from './StudentsInfo.module.scss';
import Text from '../../atoms/text/Text';
import Backdrop from '../../atoms/backdrop/Backdrop';
import HtmlTag from '../htmlTag/HtmlTag';
import { useMediaQuery } from 'react-responsive';
import closeSvg from '../../../assets/icons/close.svg';

const StudentsInfo = ({
  title,
  description,
  descriptionClass,
  doubleQuoteLeft,
  doubleQuoteRight,
  containerClass,
  footerText,
  date,
  isTestimonial,
  showData = true,
  onReadMoreClick,
  color,
  hasClose,
  onClose,
  footerClass,
  limited,
  isRtl,
  limitHeight,
  isModal,
}) => {
  const containerClasses = `${styles['students-info']} ${containerClass || ''}`;
  const isTablet = useMediaQuery({ maxWidth: 1200 });
  const isMobile = useMediaQuery({ maxWidth: 600 });
  return (
    <div
      className={`${containerClasses} ${
        isTestimonial ? styles['testimonial'] : ''
      }`}
      style={{ direction: isRtl ? 'rtl' : 'ltr' }}
    >
      <img
        className={styles['left-quotes']}
        src={doubleQuoteLeft}
        alt="Left Quote"
      />
      <img
        className={styles['right-quotes']}
        src={doubleQuoteRight}
        alt="Right Quote"
      />
      <div className={styles['right-border']}></div>
      <div className={styles['left-border']}></div>
      <div className={styles['header-container']}>
        <div>
          <HtmlTag
            title={title}
            variant="text-bold-italic-20"
            style={{ textTransform: 'uppercase' }}
            color={color}
          />
          {!isTestimonial && (
            <Text title={footerText} variant="text-regular-16" color={color} />
          )}
        </div>

        {hasClose ? (
          <img src={closeSvg} className={styles.closeSvg} onClick={onClose} />
        ) : null}
      </div>
      <div className={styles['desc-wrapper']}>
        <div className={`${styles['description']} ${descriptionClass}`}>
          <HtmlTag
            title={description}
            variant="text-regular-18"
            externalLineHeight={isTablet ? 1.2 : 2}
            maxLines={
              isModal ? 100 : isMobile ? 5 : isTablet ? 10 : limited ? 5 : null
            }
            color={color}
          />
        </div>
        {showData && isTestimonial && (
          <div className={`${styles['footer']} ${footerClass ?? ''}`}>
            <HtmlTag
              title={footerText}
              variant="text-regular-16"
              color={color}
            />
            {isTestimonial && <Text title={date} variant="text-regular-14" />}
            {!isTestimonial && <br />}
          </div>
        )}
        {onReadMoreClick ? (
          <div className={styles['read-more']}>
            <Text
              title="home.read_more"
              variant="text-regular-16"
              onClick={() => onReadMoreClick()}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default StudentsInfo;
