import React, { useEffect, useState } from 'react';
import RoutesData from '../../../routes/factory/routesData';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  SearchInput,
  SearchInputAnimated,
} from '../../atoms/searchInput/SearchInput';

import Text from '../../atoms/text/Text';
import RoundedButton from '../../atoms/roudedButton/RoundedButton';
import LanguageToggle from '../../atoms/languageToggle/LanguageToggle';

//SVG imports
import govLogo from './factory/logo-svg/gov-logo.svg';
import accessibilitySVG from '../../../assets/icons/accessibility.svg';
import academy from '../../../assets/icons/academy.svg';
import navArrow from '../../../assets/icons/navArrow.svg';
import logo from './factory/logo-svg/Layer_1-2.svg';
import dubailogo from './factory/logo-svg/Group 43736.svg';
import logoLine from './factory/logo-svg/Vector.svg';

import searchSvg from '../../../assets/icons/searchSvg.svg';

import styles from './Header.module.scss';
import SideModal from '../../hoc/sideModel/SideModal';
import Navigator from '../../molecule/navigationBar/Navigator';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BoxButtonGroup from '../../molecule/boxButtonGroup/BoxButtonGroup';
import { fontsData, volumeData } from './factory/HeaderData';
import { setFontSize } from '../../../app/globals/slice/fontSizeSlice/FontSizeSlice';
import { setVolume } from '../../../app/globals/slice/volume/VolumeState';
import {
  toggleAccessModal,
  toggleNavigationBar,
} from '../../../app/globals/slice/sideModalSlice/SideModalSlice';

const Header = ({ fixed, isHomePage }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const degreeData = useSelector((state) => state.degreeData?.degreeData || []);
  const fontSize = useSelector((state) => state.fontSize);
  const volume = useSelector((state) => state.volume);

  const currentLanguage = i18n.language;
  const isRtl = currentLanguage !== 'en';

  const navigate = useNavigate();
  const location = useLocation();
  const [searchMobileToggle, setSearchMobileToggle] = useState(false);
  const { navigationBar, access } = useSelector((state) => state.sideModal);

  const handleOpenNavigator = () => {
    dispatch(toggleNavigationBar());
  };
  const [navigatorData, setNavigatorData] = useState(degreeData || []);
  const handleFontClick = (mode) => {
    dispatch(setFontSize(mode));
  };
  const handleVolume = (mode) => {
    dispatch(setVolume(mode));
  };

  const handleAccessibility = (flag) => {
    dispatch(toggleAccessModal());
  };
  const handleSearchClick = (flag) => {
    setSearchMobileToggle(flag);
  };
  // injecting the degrees to the array for navigation
  useEffect(() => {
    // Function to transform the degree data
    const transformDegreeData = (degreeData) => {
      const mappedDegrees = {
        bachelors: [],
        masters: [],
        phds: [],
      };

      degreeData?.items?.forEach((item) => {
        const degreeUrl = item.degreeNameEn?.replace(/\s+/g, '-');
        const degreeInfo = {
          title: i18n.language === 'en' ? item.degreeNameEn : item.degreeNameAr,
          route: `/degree-programs/degree/${degreeUrl}/${item.degreeId}`,
        };

        switch (item.degreeType) {
          case 0:
            mappedDegrees?.bachelors?.push(degreeInfo);
            break;
          case 1:
            mappedDegrees?.masters?.push(degreeInfo);
            break;
          case 2:
            mappedDegrees?.phds?.push(degreeInfo);
            break;
          default:
            break;
        }
      });

      return RoutesData?.map((section) => {
        if (section.key === 'degrees') {
          return {
            ...section,
            children: section?.children?.map((child) => {
              if (child.key === 'bachelors') {
                return { ...child, children: mappedDegrees?.bachelors };
              } else if (child.key === 'masters') {
                return { ...child, children: mappedDegrees?.masters };
              } else if (child.key === 'phd') {
                return { ...child, children: mappedDegrees?.phds };
              }
              return child;
            }),
          };
        }
        return section;
      });
    };

    // Check if degree data is available and transform it
    if (degreeData && degreeData.items) {
      const transformedData = transformDegreeData(degreeData);
      setNavigatorData(transformedData);
    } else {
      // Handle the scenario when degreeData is not available
      const updatedData = RoutesData.filter(
        (section) => section.title !== 'degree & programs'
      );
      setNavigatorData(updatedData);
    }
  }, [degreeData, i18n.language]);
  return (
    <nav className={`${styles['header']} ${fixed ? styles['fixed'] : ''}`}>
      <div className={styles['header-wrapper']}>
        <div className={styles['header-actions']}>
          <div className={styles['actions']}>
            <img
              src={govLogo}
              alt="dubai police Logo"
              onClick={() => window.open('https://www.dubai.ae/', '_blank')}
              className={styles['gov-logo']}
            />
            <div className={styles.buttons}>
              <RoundedButton
                title="home.Sign_In"
                variant="text-regular-20"
                // onClick={handleSignInModal}
                onClick={() =>
                  window.open(
                    'https://dubaipolice.ac.ae/dpacademy/ar/std_login.jsp',
                    '_blank'
                  )
                }
                buttonClass={styles.signin}
                maxline={1}
              />
              <RoundedButton
                svg={academy}
                classname={styles.interaction}
                onClick={() => navigate('/coming-soon')}
                isbordered
              />
              <RoundedButton
                svg={accessibilitySVG}
                onClick={() => handleAccessibility(true)}
                isbordered
              />
              <LanguageToggle className={styles['language-toggle']} />
            </div>
          </div>
        </div>
        <div className={styles['header-container']}>
          <div className={styles.logo}>
            <SearchInputAnimated animated isNavigation data={RoutesData} />
            <div className={styles['mobile-search-svg']}>
              <RoundedButton
                svg={searchSvg}
                onClick={() => handleSearchClick(true)}
                isbordered
              />
            </div>
            <div
              className={styles['logo-container']}
              onClick={() => navigate('/')}
            >
              <img
                src={logo}
                alt="dubai police Logo"
                onClick={() => navigate('/')}
                className={styles['prime-logo']}
              />
              <img
                src={logoLine}
                alt=""
                onClick={() => navigate('/')}
                className={styles['dash-logo']}
              />
              <img
                src={dubailogo}
                alt="dubai police Logo"
                onClick={() => navigate('/')}
                className={styles['dubaiPolice-logo']}
              />
            </div>

            <div className={styles['mobile-search-svg']}>
              <RoundedButton
                // animated
                svg={navArrow}
                onClick={handleOpenNavigator}
                isbordered
                classname={styles.button}
              />
            </div>
          </div>

          <div className={styles.navigation}>
            <div
              style={{ direction: isRtl ? 'ltr' : 'rtl' }}
              className={styles.navigation}
            >
              <Text
                onClick={() => navigate('/news-and-events/news')}
                title="common.News_And_Events"
                variant="text-bold-20"
                className={`${styles.link} ${location.pathname.startsWith('/news-and-events') ? styles.active : ''}`}
              />{' '}
              <Text
                onClick={() => navigate('/academics/contact-us')}
                title="home.Contact_Us_2"
                variant="text-bold-20"
                className={`${styles.link} ${location.pathname.startsWith('/academics') ? styles.active : ''}`}
              />{' '}
              <Text
                onClick={() => navigate('/about/overview')}
                title="home.About_Us"
                variant="text-bold-20"
                className={`${styles.link} ${location.pathname.startsWith('/about') ? styles.active : ''}`}
              />
              {!isHomePage ? (
                <Text
                  onClick={() => navigate('/')}
                  title="home.home_page"
                  variant="text-bold-20"
                  className={`${styles.link} ${
                    location.pathname === '/' ? styles.active : ''
                  }`}
                />
              ) : null}
            </div>
            <RoundedButton
              animated
              svg={navArrow}
              onClick={handleOpenNavigator}
              isbordered
              classname={styles.button}
            />
          </div>
        </div>
      </div>

      <div className={styles['buttons-tablet']}>
        <div className={styles['button-wrapper']}>
          <RoundedButton
            title="home.Sign_In"
            variant="text-regular-20"
            onClick={() => window.open('https://dpa.blackboard.com/', '_blank')}
            buttonClass={styles.signin}
            maxline={1}
          />
          <RoundedButton
            svg={accessibilitySVG}
            onClick={() => handleAccessibility(true)}
            isbordered
          />

          <RoundedButton
            svg={searchSvg}
            onClick={() => handleSearchClick(true)}
            isbordered
          />
        </div>
        <RoundedButton
          animated
          svg={navArrow}
          onClick={handleOpenNavigator}
          isbordered
          classname={styles.button}
        />
      </div>
      <SideModal
        title="navigationMenu.main_page"
        isbackdropped={false}
        style={{ width: '100%' }}
        isOpenModal={navigationBar}
        // onClose={() => handleNavigationList(false)}
      >
        <div className={styles['navigation-container']}>
          <Navigator data={navigatorData} isRtl={isRtl} />
        </div>
      </SideModal>

      <SideModal
        title={'accessibility.accessibility'}
        isbackdropped={true}
        isOpenModal={access}
        onClose={() => handleAccessibility(false)}
      >
        <div
          className={`${styles['accessibility-container']} ${
            isRtl ? 'rtl' : ''
          }`}
        >
          <div className={styles['lang-container-mobile']}>
            <LanguageToggle />
          </div>
          <BoxButtonGroup
            title={'accessibility.font_size'}
            data={fontsData}
            onItemClick={handleFontClick}
            state={fontSize}
          />

          <BoxButtonGroup
            title={t('accessibility.website_volume')}
            data={volumeData}
            onItemClick={handleVolume}
            state={volume}
          />
          {/* <BoxButtonGroup
            title={t('accessibility.colors')}
            data={webMode}
            onItemClick={() => {}}
          /> */}
        </div>
      </SideModal>
      <SideModal
        title={'Search'}
        isbackdropped={true}
        isOpenModal={searchMobileToggle}
        onClose={() => handleSearchClick(false)}
        isFromTop
      >
        <div
          className={styles['search-container']}
          onMouseEnter={() => (window.isHoveringCalendar = true)}
          onMouseLeave={() => (window.isHoveringCalendar = false)}
          onTouchStart={() => (window.isHoveringCalendar = true)}
          onTouchEnd={() => {
            window.isHoveringCalendar = false;
          }}
        >
          <SearchInput
            inputClassname={styles.input}
            data={RoutesData}
            isNavigation
            hasClear={false}
            isMobile
          />
        </div>
      </SideModal>
    </nav>
  );
};

export default Header;
