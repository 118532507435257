// LazyImports.js
import { lazy } from 'react';

// Lazy pages
const Register = lazy(() => import('../../features/register/Register'));
const AcademicCalendar = lazy(
  () => import('../../features/academicCalendar/AcademicCalendar')
);
const RegistrationApp = lazy(
  () => import('../../features/registrationApplication/RegistrationApp')
);
const Policies = lazy(() => import('../../features/policies/Policies'));
const Research = lazy(() => import('../../features/research/Research'));
const SubmitResearch = lazy(
  () => import('../../features/submitResearch/SubmitResearch')
);
const ResearchOportunity = lazy(
  () => import('../../features/researchOportunity/ResearchOportunity')
);
const TrainingCenter = lazy(
  () => import('../../features/trainingCenter/TrainingCenter')
);

const AcademyAdvizingQuiz = lazy(
  () => import('../../features/academicAdvizingQuiz/AcademicAdvizingQuiz')
);
const AcademyInNumber = lazy(
  () => import('../../features/academyInNumbers/AcademyInNumber')
);
const AcademyEcoMagazine = lazy(
  () => import('../../features/academyEcoMagazine/AcademyEcoMagazine')
);

const AboutTheAcademy = lazy(
  () => import('../../features/aboutTheAcademy/AboutTheAcademy')
);
const Overview = lazy(
  () => import('../../features/aboutTheAcademy/outlets/overview/Overview')
);
const Graduation = lazy(
  () => import('../../features/aboutTheAcademy/outlets/graduation/Graduation')
);
const OrganizationalChart = lazy(
  () =>
    import(
      '../../features/aboutTheAcademy/outlets/organizationalChart/OrganizationalChart'
    )
);
const Team = lazy(
  () => import('../../features/aboutTheAcademy/outlets/team/Team')
);

const Academics = lazy(() => import('../../features/academics/Academics'));
const AcademicsOverview = lazy(
  () =>
    import(
      '../../features/academics/outlets/academicsOverview/AcademicsOverview'
    )
);
const CollegePostGraduateStudies = lazy(
  () =>
    import(
      '../../features/academics/outlets/collegePostGraduateStudies/College_PostGraduateStudies'
    )
);
const CollegeOfLaw = lazy(
  () => import('../../features/academics/outlets/collegeOfLaw/CollegeOfLaw')
);
const AcademicSupport = lazy(
  () =>
    import('../../features/academics/outlets/academicSupport/AcademicSupport')
);
const AcademicAdvising = lazy(
  () =>
    import(
      '../../features/academics/outlets/academicAdvising/Academic_Advising'
    )
);

const NewsAndEvents = lazy(
  () => import('../../features/news&events/NewsAndEvents')
);
const NewsPage = lazy(
  () =>
    import(
      '../../components/templates/newsAndEventsTemplate/newsTemplate/NewsPage'
    )
);
const EventPage = lazy(
  () =>
    import(
      '../../components/templates/newsAndEventsTemplate/eventTemplate/EventPage'
    )
);
const NewsLetterPage = lazy(
  () =>
    import(
      '../../components/templates/newsAndEventsTemplate/newsLetterTemplate/NewsLetterPage'
    )
);
const MediaGallery = lazy(
  () =>
    import(
      '../../components/templates/newsAndEventsTemplate/mediaGallery/MediaGallery'
    )
);

const Admissions = lazy(() => import('../../features/admissions/Admissions'));
const AdmissionOverview = lazy(
  () => import('../../features/admissions/outlets/overview/AdmissionOverview')
);
const TuitionFees = lazy(
  () => import('../../features/admissions/outlets/tuitionFees/TuitionFees')
);
const UnderGraduate = lazy(
  () => import('../../features/admissions/outlets/undergraduate/Undergraduate')
);
const Graduate = lazy(
  () => import('../../features/admissions/outlets/graduate/Graduate')
);
const ScholarshipFinancial = lazy(
  () =>
    import(
      '../../features/admissions/outlets/scholarship&financial/Scholarship&financial'
    )
);

const StudentAffairs = lazy(
  () => import('../../features/studentAffairs/StudentAffairs')
);
const Facilities = lazy(
  () => import('../../features/studentAffairs/outlets/facilities/Facilities')
);

const StudentLife = lazy(
  () => import('../../features/studentAffairs/outlets/studentLife/StudentLife')
);

const Alumni = lazy(
  () => import('../../features/studentAffairs/outlets/alumni/Alumni')
);
const Tour = lazy(
  () => import('../../features/studentAffairs/outlets/tour/Tour')
);
const StudentServices = lazy(
  () =>
    import(
      '../../features/studentAffairs/outlets/studentServices/StudentServices'
    )
);
// const AccessibilityServices = lazy(
//   () =>
//     import(
//       '../../features/studentAffairs/outlets/AccessibilityServices/AccessibilityServices'
//     )
// );

const Council = lazy(() => import('../../features/council/Council'));
const CouncilOverview = lazy(
  () => import('../../features/council/outlet/overview/CouncilOverview')
);
const StudentsCouncil = lazy(
  () => import('../../features/council/outlet/studentsCouncil/StudentsCouncil')
);
const CouncilCalendar = lazy(
  () => import('../../features/council/outlet/councilCalendar/CouncilCalendar')
);
const DegreeAndPrograms = lazy(
  () => import('../../features/degree&programs/DegreeAndPrograms')
);

const ResearchAndPublications = lazy(
  () =>
    import(
      '../../features/academyEcoMagazine/outlets/researchAndPublications/ResearchAndPublications'
    )
);

const SecurityAndLaw = lazy(
  () =>
    import(
      '../../features/academyEcoMagazine/outlets/securityAndLow/SecurityAndLaw'
    )
);
const ResearchGuideline = lazy(
  () => import('../../features/researchGuideline/ResearchGuideline')
);

export {
  DegreeAndPrograms,
  SecurityAndLaw,
  ResearchAndPublications,
  ResearchGuideline,
  Register,
  AcademicCalendar,
  RegistrationApp,
  Policies,
  Research,
  SubmitResearch,
  ResearchOportunity,
  TrainingCenter,
  AcademyAdvizingQuiz,
  AcademyInNumber,
  AcademyEcoMagazine,
  AboutTheAcademy,
  Overview,
  Graduation,
  OrganizationalChart,
  Team,
  Academics,
  AcademicsOverview,
  CollegePostGraduateStudies,
  CollegeOfLaw,
  AcademicSupport,
  AcademicAdvising,
  NewsAndEvents,
  NewsPage,
  EventPage,
  NewsLetterPage,
  MediaGallery,
  Admissions,
  AdmissionOverview,
  TuitionFees,
  UnderGraduate,
  Graduate,
  ScholarshipFinancial,
  StudentAffairs,
  Facilities,
  Alumni,
  Tour,
  StudentServices,
  // AccessibilityServices,
  Council,
  CouncilOverview,
  StudentsCouncil,
  CouncilCalendar,
  StudentLife,
};
