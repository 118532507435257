import React, { useEffect, useRef } from 'react';
import styles from './SlideOne.module.scss';
import Text from '../../../../../atoms/text/Text';
import MajorCard from '../../../../../molecule/bachelorCard/MajorCard';
import employee from '../../../../../../assets/icons/employee.svg';
import bachelorBoy from '../../../../../../assets/icons/students-degrees/Bachelorsboy.png';
import bachelorGirl from '../../../../../../assets/icons/students-degrees/Bachelorsgirl.png';
import degreeStyles from '../../Degrees.module.scss';
import ScrollReveal from 'scrollreveal';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const SlideOne = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const degreeData = useSelector((state) => state?.degreeData?.degreeData);

  const bachelorsData = degreeData?.items
    ?.filter((item) => item?.degreeType === 0 && item?.degreeNameEn)
    .slice(0, 8);

  const titleRef = useRef(null);
  const boxRef = useRef(null);
  useEffect(() => {
    if (titleRef.current) {
      ScrollReveal().reveal(titleRef.current, {
        delay: 100,
        duration: 500,
        easing: 'ease-in-out',
        opacity: 0,
        scale: 0.9,
        distance: '20px',
        reset: true,
      });
    }

    if (boxRef.current) {
      ScrollReveal().reveal(boxRef.current.children, {
        delay: 500,
        duration: 500,
        easing: 'ease-in-out',
        opacity: 0,
        scale: 0.9,
        distance: '20px',
        reset: true,
        interval: 200,
      });
    }
  }, []);

  return (
    <div className={styles['slide']}>
      {/* <img className={degreeStyles.student1} src={bachelorBoy} loading="lazy" />
      <img
        className={degreeStyles.student2}
        src={bachelorGirl}
        loading="lazy"
      /> */}
      <div ref={titleRef} className={styles['content']}>
        <Text
          title="home.bachelor_degree"
          variant="text-bold-italic-50"
          style={{ textTransform: 'uppercase' }}
          isLineBreak
          textContainerStyle={{ textAlign: 'center' }}
        />
        <div className={styles['grid-container']}>
          <div
            ref={boxRef}
            className={`${styles['grid']}`}
            style={{ direction: currentLanguage === 'ar' ? 'rtl' : '' }}
          >
            {bachelorsData?.slice(0, 6).map((item) => {
              if (item.degreeNameEn) {
                return (
                  <MajorCard
                    key={item.degreeId}
                    id={item.degreeId}
                    titleEN={item.degreeNameEn}
                    title={
                      currentLanguage === 'en'
                        ? item.degreeNameEn
                        : item.degreeNameAr
                    }
                    className={styles.card}
                    isCadet={item.forAllOrCadets}
                    svg={employee}
                  />
                );
              } else return <></>;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideOne;
