import React from 'react';

const RoutesData = [
  // About the Academy
  {
    title: 'navigationMenu.about_the_academy',
    route: '/about/overview',
    searchName: 'about the academy',
    children: [
      {
        title: 'About_The_Academy.Header-Title',
        route: '/about/overview',
        searchName: 'overview',
      },
      { title: 'navigationMenu.history', route: '/about/history' },
      // { title: 'navigationMenu.graduation', route: '/about/graduation' },
      { title: 'navigationMenu.team', route: '/about/team' },
      { title: 'navigationMenu.chart', route: '/about/leadership' },
     
    ],
  },

  // Academics
  {
    title: 'navigationMenu.academics',
    route: '/academics/overview',
    children: [
      { title: 'overview.academicsOverview', route: '/academics/overview' },
      {
        title: 'navigationMenu.college_postgraduate',
        route: '/academics/college-postgraduate',
      },
      {
        title: 'navigationMenu.college_law_police',
        route: '/academics/college-law-police',
      },
      {
        title: 'navigationMenu.academic_calendar',
        route: '/academic-calendar',
      },
      {
        title: 'navigationMenu.academic_support',
        route: '/academics/academic-support',
      },
      {
        title: 'navigationMenu.academy_advising',
        route: '/academics/contact-us',
      },
    ],
  },
  //degrees
  {
    key: 'degrees',
    title: 'navigationMenu.degree_and_programs',
    route: '/degree-programs',
    routeState: { state: { type: 1 } },
    children: [
      {
        hidden: true,
        title: 'overview',
        route: '/degree-programs',
        routeState: { state: { type: 1 } },
        index: true,
      },
      {
        key: 'bachelors',
        title: 'navigationMenu.bachelors',
        route: '/degree-programs',
        routeState: { state: { type: 2 } },
      },
      {
        key: 'masters',
        title: 'navigationMenu.masters',
        route: '/degree-programs',
        routeState: { state: { type: 3 } },
      },
      {
        key: 'phd',
        title: 'navigationMenu.phd',
        route: '/degree-programs',
        routeState: { state: { type: 4 } },
      },
    ],
  },
  // Admission
  {
    title: 'navigationMenu.admission',
    route: '/admissions/overview',
    children: [
      { title: 'navigationMenu.overview', route: '/admissions/overview' },
      {
        title: 'navigationMenu.tuition_fees',
        route: '/admissions/tuition-fees',
      },
      {
        title: 'navigationMenu.undergraduate',
        route: '/admissions/undergraduate',
      },
      { title: 'navigationMenu.graduate', route: '/admissions/graduate' },
      {
        title: 'navigationMenu.scholarships_financial_aid',
        route: '/admissions/scholarships-financial-aid',
      },
    ],
  },
  // Students Affairs
  {
    title: 'navigationMenu.student_life',
    route: '/students-affairs/student-life',
    children: [
      {
        title: 'navigationMenu.academic_journey',
        route: '/students-affairs/student-life',
      },
      {
        title: 'navigationMenu.facilities',
        route: '/students-affairs/facilities',
      },
      // {
      //   title: 'navigationMenu.campus_experience',
      //   route: '/students-affairs/campus-experience',
      // },
      { title: 'navigationMenu.tour', route: '/coming-soon' },
      { title: 'navigationMenu.alumni', route: '/students-affairs/alumni' },
      {
        title: 'studentServices.Student_Services',
        route: '/students-affairs/student-services',
      },

      // {
      //   title: 'studentServices.student_accessibility',
      //   route: '/students-affairs/student-accessibility-services',
      // },
    ],
  },

  {
    title: 'navigationMenu.news_and_events',
    route: '/news-and-events/news',
    children: [
      {
        title: 'navigationMenu.news_page',
        route: '/news-and-events/news',
      },
      {
        title: 'navigationMenu.newsletter',
        route: '/news-and-events/news-letter',
      },
      {
        title: 'navigationMenu.events_page',
        route: '/news-and-events/events',
      },

      {
        title: 'bottomNavigation.Media',
        route: '/news-and-events/media-gallery',
      },
    ],
  },

  // {
  //   title: 'council.council',
  //   route: '/council/overview',
  //   children: [
  //     { title: 'navigationMenu.overview', route: '/council/overview' },
  //     {
  //       title: 'students_council.students_council',
  //       route: '/council/students-council',
  //     },
  //     {
  //       title: 'parents_council.parents_council',
  //       route: '/council/parents-council',
  //     },
  //     {
  //       title: 'council.council_calendar',
  //       route: '/council/council-calendar',
  //     },
  //     {
  //       title: 'council.faculty_members_council',
  //       route: '/council/faculty-member-council',
  //     },
  //     {
  //       title: 'council.cadets_students_council',
  //       route: '/council/cadets-students-council',
  //     },
  //   ],
  // },
  {
    title: 'Magazine.magazine',
    route: '/academy-eco-magazine',
    children: [
      {
        title: 'Magazine.Academy_Echo_Magazine',
        route: '/academy-eco-magazine',
      },
      {
        title: 'navigationMenu.security_law',
        route: '/security-and-law',
      },
      {
        title: 'navigationMenu.Research_publications',
        route: '/academic-research-and-publications',
      },
      {
        title: 'academics.GUIDELINES',
        route: '/research/guideline',
      },
    ],
  },
  // Academic Calendar
  {
    title: 'navigationMenu.academic_calendar',
    route: '/academic-calendar',
  },
  // {
  //   title: 'navigationMenu.Echo_Magazine',
  //   route: '/academy-eco-magazine',
  // },
  {
    title: 'research.research',
    route: '/research',
  },
  {
    title: 'training.training_center',
    route: '/training-center',
  },
  {
    title: 'navigationMenu.Statistics',
    route: '/academy-in-number',
  },
  // {
  //   title: 'academics.GUIDELINES',
  //   route: '/research/guideline',
  // },
];

export default RoutesData;
