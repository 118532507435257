import React, { useState, useEffect } from 'react';

import calendar from '../../../assets/icons/calendar.svg';
import clock from '../../../assets/icons/clock.svg';
import arowDown from '../../../assets/icons/downArrow.svg';
import location from '../../../assets/icons/location-pin.svg';
import image from '../../../assets/images/dummy.png';
import styles from './DetailCard.module.scss';
import Text from '../../atoms/text/Text';

import ReadMoreModal from '../../../components/organism/readMoreModal/ReadMoreModal';
import defaultImage from '../../../assets/images/defaultNews.png';

import { useTranslation } from 'react-i18next';
import isRtl from '../../../app/helpers/isRtl';
import HtmlTag from '../htmlTag/HtmlTag';

const DetailCard = ({ event, className }) => {
  const {
    date,
    time,
    categoryName,
    address,
    title,
    description,
    imageUrl1,
    imageUrl2,
    locationLink,
    titleClass,
  } = event;
  const { t, i18n } = useTranslation();
  console.log('asdasdasdasd', imageUrl1);
  const currentLanguage = i18n.language !== 'en';
  const [externalShowMore, setExternalShowMore] = useState(false);
  return (
    <div className={styles.card}>
      <div className={`${styles.image} ${className ?? ''}`}>
        <img
          className={styles.right}
          src={imageUrl1 || defaultImage}
          alt="event image"
          loading="lazy"
          onError={(e) => (e.target.src = defaultImage)}
        />

        <img
          className={styles.right}
          src={imageUrl2 || defaultImage}
          alt="event image"
          loading="lazy"
          onError={(e) => (e.target.src = defaultImage)}
        />
      </div>
      <div className={styles['content-container']}>
        <div className={`${styles.titles} ${titleClass ?? ''}`}>
          <Text
            svg={calendar}
            svgPosition="left"
            title={date}
            variant="text-bold-italic-16"
            style={{ textTransform: 'uppercase' }}
            className={styles.text}
          />
          <Text
            svg={clock}
            svgPosition="right"
            title={time}
            variant="text-bold-italic-16"
            style={{
              textTransform: 'uppercase',
              direction: 'ltr',
              bacground: 'red',
            }}
            className={styles.text}
          />
          {/* <Text
            title={categoryName}
            variant="text-bold-italic-16"
            style={{ textTransform: 'uppercase' }}
            className={styles.text}
          /> */}
        </div>
        <div
          className={styles.content}
          style={{
            textAlign: currentLanguage ? 'right' : '',
            direction: currentLanguage ? 'rtl' : '',
          }}
        >
          <Text
            title={locationLink}
            variant="text-regular-16"
            className={styles.location}
            svg={location}
            svgPosition="left"
          />
          <Text
            title={title}
            variant="text-bold-18"
            style={{ textTransform: 'uppercase' }}
            maxLines={1}
          />
          <HtmlTag
            title={description}
            variant="text-regular-16"
            className={styles.description}
            maxLines={2}
          />
          <div
            className={styles['view-more']}
            onClick={() => setExternalShowMore(true)}
            style={{
              textAlign: currentLanguage ? 'right' : '',
              direction: currentLanguage ? 'ltr' : '',
              textTransform: 'uppercase',
            }}
          >
            <Text
              title="common.read_more"
              variant="text-bold-italic-16"
              style={{ textTransform: 'uppercase', cursor: 'pointer' }}
              svg={arowDown}
              svgPosition="right"
            />
          </div>
        </div>
      </div>

      <ReadMoreModal
        isOpen={externalShowMore}
        closeModal={() => setExternalShowMore(false)}
        // image={`${url}${imgUrl2}`}
        title={title}
        description={description}
        date={date}
        time={time}
        address={address}
        isAddress
        // categoryName={address}
        locationLink={locationLink}
        isTitled
        image={[imageUrl1, imageUrl2]}
      />
    </div>
  );
};

export default DetailCard;
