import React from 'react';
import styles from './List.module.scss';
import Text from '../../atoms/text/Text';
import { useTranslation } from 'react-i18next';

const List = ({
  items,
  direction = 'wrap',
  listStyle,
  title,
  containerStyle,
  type = 'ul',
  itemPaddingStart,
  itemClassName,
  textVariant,
  color,
  liClass,
}) => {
  const { i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const listItemStyle = {
    paddingInlineStart: itemPaddingStart,
    ...listStyle,
  };
  const listContainerStyle = {
    textAlign: isRtl ? 'right' : '',
    ...containerStyle,
  };

  return (
    <div
      className={`${styles['list-container']} ${isRtl ? 'rtl' : styles.ltr}`}
      style={listContainerStyle}
    >
      <Text
        title={title}
        variant="text-regular-18-black"
        style={{ textTransform: 'uppercase' }}
      />
      {type === 'ul' ? (
        <ul
          className={`${styles['list']} ${styles[direction]} ${liClass}`}
          style={listItemStyle}
        >
          {items.map((item, index) => (
            <li
              key={index}
              className={`${itemClassName} ${
                direction === 'column' ? '' : styles['list-item']
              }`}
            >
              <Text
                title={item}
                variant="text-regular-16-black"
                className={styles.text}
                style={{
                  color: color ? color : '',
                  marker: color,
                  lineHeight: 2,
                }}
              />
            </li>
          ))}
        </ul>
      ) : (
        <ol
          className={`${styles['list']} ${styles[direction]}`}
          style={listItemStyle}
        >
          {items.map((item, index) => (
            <li
              key={index}
              className={`${itemClassName} ${
                direction === 'column' ? '' : styles['list-item']
              }`}
            >
              <Text
                title={item}
                variant={textVariant ?? 'text-regular-16-black'}
                className={styles.text}
                style={{ color: color ? color : '', lineHeight: 2 }}
              />
            </li>
          ))}
        </ol>
      )}
    </div>
  );
};

export default List;
