import React, { useEffect, useState } from 'react';
import styles from './Events.module.scss';
import arowDown from '../../../../../../../assets/icons/downArrow.svg';
import NavigatorStepper from '../../../../../../molecule/navigationStepper/NavigatorStepper';
import DetailCard from '../../../../../../molecule/detailCard/DetailCard';
import Text from '../../../../../../atoms/text/Text';
import Button from '../../../../../../atoms/button/Button';
import endpoints from '../../../../../../../api/endpoints';
import { useGetQuery } from '../../../../../../../services/apiCall';
import { useTranslation } from 'react-i18next';
import Loader from '../../../../../../atoms/loader/Loader';
import { useNavigate } from 'react-router-dom';

import DOMPurify from 'dompurify';

import defaultNews from '../../../../../../../assets/images/defaultNews.png';

const Events = () => {
  const navigate = useNavigate();

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const apiUrl = endpoints.news.getEvents;
  const params = ['3', '1'];
  const { data, error, isLoading, refetch } = useGetQuery({
    apiUrl: apiUrl,
    params: params,
  });
  const [selectedEventIndex, setSelectedEventIndex] = useState(0);

  const handleStepChange = (index) => {
    setSelectedEventIndex(index);
  };
  if (isLoading) return <Loader />;
  const formatDate = (isoDateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = new Date(isoDateString).toLocaleDateString(
      currentLanguage === 'en' ? 'en-US' : 'ar-EG',
      options
    );

    return formattedDate.replace(/-|\./g, '/');
  };
  const formatTime = (dateString) => {
    const date = new Date(dateString);
    // Extracting hours and minutes and formatting as HH : MM AM/PM
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const strTime = `${hours.toString().padStart(2, '0')} : ${minutes
      .toString()
      .padStart(2, '0')} ${ampm}`;
    return strTime;
  };

  const selectedEvent = data?.items ? data.items[selectedEventIndex] : null;

  return (
    <div className={styles['events-container']}>
      <div className={styles.grid}>
        <NavigatorStepper
          eventsData={data?.items ?? []}
          onStepChange={handleStepChange}
          selectedStep={selectedEventIndex}
          setSelectedStep={setSelectedEventIndex}
        />
        {selectedEvent && (
          <DetailCard
            event={{
              date: formatDate(selectedEvent.eventDate),
              time: formatTime(selectedEvent.eventDate),
              imageUrl1: DOMPurify.sanitize(`${selectedEvent.url}${selectedEvent.image1}`),
              imageUrl2: DOMPurify.sanitize(`${selectedEvent.url}${selectedEvent.image2}`),
              // categoryName:
              //   currentLanguage === 'en'
              //     ? selectedEvent.category_EN
              //     : selectedEvent.category_AR,
              locationLink: selectedEvent.locationLink,
              title:
                currentLanguage === 'en'
                  ? selectedEvent.title_EN
                  : selectedEvent.title_AR,
              description:
                currentLanguage === 'en'
                  ? selectedEvent.eventText_EN
                  : selectedEvent.eventText_AR,
            }}
            // className={styles.card}
            titleClass={styles.titleClass}
          />
        )}
      </div>
      <Button
        title="home.view_all_the_news"
        variant="text-regular-20"
        style={{ textTransform: 'uppercase' }}
        styleType="primary"
        className={styles['view-all-button']}
        svg={arowDown}
        svgPosition="right"
        onClick={() => navigate('/news-and-events/events')}
      />
      <div className={styles['view-all']}>
        <Text
          title="home.view_all_Events"
          variant="text-bold-italic-16"
          className={styles['text']}
          svg={arowDown}
          svgPosition="right"
          onClick={() => navigate('/news-and-events/events')}
        />
      </div>
    </div>
  );
};

export default Events;
