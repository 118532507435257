import React, { useState } from 'react';
import rightArrow from '../../../../../assets/icons/linkRightArrow.svg';
import styles from './Factory.module.scss';
import Text from '../../../../atoms/text/Text';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { closeAllModals } from '../../../../../app/globals/slice/sideModalSlice/SideModalSlice';

const navigateTo = (item, navigate, dispatch) => {
  if (!item?.route) return;
  dispatch(closeAllModals());
  setTimeout(() => {
    navigate(item.route, {
      state: item.routeState,
      replace: item.shouldReplace,
    });
  }, 500);
};

const MobileMenu = ({ data, className, isRtl }) => {
  const [activeLink, setActiveLink] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (index, item) => {
    if (!item?.children && item.route) {
      navigateTo(item, navigate, dispatch);
    } else {
      setActiveLink(activeLink === index ? null : index);
    }
  };

  return (
    <div
      className={`${styles.menu} ${className ?? ''} ${
        isRtl ? styles['rtl-menu'] : ''
      }`}
    >
      {data?.map((item, index) => {
        if (item.hidden) return null;

        const textClass = `${styles.link} ${
          activeLink === index ? styles['active-link'] : ''
        }`;

        return (
          <React.Fragment key={item.id || index}>
            <Text
              variant="text-bold-italic-18"
              title={item.title}
              style={{ textTransform: 'uppercase' }}
              svg={item.children ? rightArrow : null}
              svgPosition="right"
              className={textClass}
              onClick={() => handleClick(index, item)}
            />
            {activeLink === index && (
              <Navigation data={item?.children} item={item} />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const Navigation = ({ data, item }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const gridClass =
    data && data[2]?.children
      ? `${styles['sub-menu']} ${styles['flexed-row']}`
      : styles['sub-menu'];

  return (
    <div className={styles.navigation}>
      <div className={gridClass}>
        {data?.map((child, index) => {
          if (child?.hidden) return null;

          if (child?.children) {
            return (
              <div
                key={child.id || `child-${index}`}
                className={styles['inner-child-container']}
              >
                <Text
                  variant="text-regular-16"
                  title={child.title}
                  className={styles['child-link']}
                  style={{ textTransform: 'uppercase' }}
                  onClick={() => navigateTo(child, navigate, dispatch)}
                />
                {child.children?.map((sub, subIndex) => (
                  <div
                    key={sub.id || `sub-${subIndex}`}
                    className={styles['inner-subs-container']}
                  >
                    <Text
                      variant="text-regular-14"
                      title={sub.title}
                      className={styles['child-link']}
                      onClick={() => navigateTo(sub, navigate, dispatch)}
                    />
                  </div>
                ))}
              </div>
            );
          } else {
            return (
              <Text
                key={child.id || `main-${index}`}
                variant="text-bold-italic-16"
                title={child.title}
                style={{ textTransform: 'uppercase' }}
                className={styles['main-child-link']}
                onClick={() => navigateTo(child, navigate, dispatch)}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export { MobileMenu, Navigation };
