import React from 'react';
import styles from './Footer.module.scss';
import footerLogo from '../../../../assets/icons/logo.svg';
import Text from '../../../atoms/text/Text';
import facebook from '../../../../assets/icons/footer/facebook.svg';
import insta from '../../../../assets/icons/footer/instagram.svg';
import youtube from '../../../../assets/icons/footer/youtube.svg';
import twitter from '../../../../assets/icons/footer/twitter.svg';
// import apple from '../../../../assets/icons/footer/apple.svg';
// import google from '../../../../assets/icons/footer/playstore.svg';
import linkedIn from '../../../../assets/icons/footer/linkedIn.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const isRtl = i18n.language !== 'en';
  return (
    <div className={styles['footer-container']}>
      <div className={styles['footer']}>
        <div className={styles['image']} onClick={() => navigate('/')}>
          <img src={footerLogo} alt="Logo" className={styles['footer-logo']} />
        </div>
        <div className={`${styles['titles-subs']} ${isRtl ? 'rtl' : ''}`}>
          <div className={`${styles['dpa-links']}`}>
            <Text
              title="footer.Quick-Links"
              variant="text-bold-italic-20"
              style={{ textTransform: 'uppercase', cursor: 'default' }}
            />
            <div className={`${styles['grid-container']}`}>
              <div className={styles['grid']}>
                <a
                  href="https://u.ae"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text
                    title="footer.uae_government"
                    variant="text-regular-14"
                    className={styles['common-text']}
                  />
                </a>
                <a
                  href="https://www.dubaipolice.gov.ae/wps/portal/home"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text
                    title="footer.dubai_police_main"
                    variant="text-regular-14"
                    style={{
                      marginTop: '10px',
                    }}
                    className={styles['common-text']}
                  />
                </a>

                <a
                  href="https://www.digitaldubai.ae"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text
                    title="footer.digital_dubai"
                    variant="text-regular-14"
                    className={styles['common-text']}
                  />
                </a>
                <a
                  href="https://efaad.dubaipolice.gov.ae/Efaad/#/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text
                    title="footer.efaad_link"
                    variant="text-regular-14"
                    className={styles['common-text']}
                  />
                </a>
                {/* <a href="/contact-us">
                <Text
                  title="footer.contact_us"
                  variant="text-regular-14"
     className={styles['common-text']}
                />
              </a> */}
              </div>
              <div className={styles['grid']}>
                <a
                  href="https://moi.gov.ae/en/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text
                    title="footer.moi"
                    variant="text-regular-14"
                    className={styles['common-text']}
                  />
                </a>
                <a
                  href="https://www.mohesr.gov.ae/ar/Pages/home.aspx"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text
                    title="footer.ministry_of_higher_education_and_scientific_research"
                    variant="text-regular-14"
                    className={styles['common-text']}
                  />
                </a>
                <a
                  href="https://www.dc.gov.ae/PublicServices/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text
                    title="footer.dubai_courts"
                    variant="text-regular-14"
                    className={styles['common-text']}
                  />
                </a>

                <a
                  href="https://ejob.dubaipolice.gov.ae"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text
                    title="footer.ejob_dp"
                    variant="text-regular-14"
                    className={styles['common-text']}
                  />
                </a>

                {/* <Text
                title="education and scientific research"
                variant="text-regular-14"
                style={{
                  textTransform: 'uppercase',
                  cursor: 'pointer',
                  fontWeight: '100',
                }}
              /> */}
              </div>
            </div>
          </div>
          <div className={styles['right-section']}>
            <div className={styles['logos']}>
              {/* <Text
                title="footer.app_download"
                variant="text-bold-italic-20"
                style={{ textTransform: 'uppercase', cursor: 'pointer' }}
              />
              <div className={styles['phone-play-svgs']}>
                <img src={apple} alt="Apple Store" />
                <img src={google} alt="Play Store" />
              </div> */}
            </div>
            <div className={styles['social-media']}>
              <Text
                title="footer.follow_us_on_social_media"
                variant="text-bold-italic-20"
                style={{ textTransform: 'uppercase', cursor: 'pointer' }}
              />
              <div className={styles['svgs']}>
                <a
                  href="https://www.linkedin.com/school/dubai-police-academy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedIn} alt="dubai police linked in" />
                </a>
                <a
                  href="https://www.facebook.com/dubaipolicehq.en/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={facebook} alt="dubai police facebook" />
                </a>
                <a
                  href="https://www.instagram.com/dubaipolicehq/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={insta} alt="dubai police instagram" />
                </a>
                <a
                  href="https://twitter.com/dubaipolicehq"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twitter} alt="dubai police twitter" />
                </a>
                <a
                  href="https://www.youtube.com/dubaipolicehq"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={youtube} alt="dubai police youtube" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['web-rights']}>
        <Text title="footer.all_right_reserved" className={styles.text} />
        <div className={styles.policies}>
          {/* <Text
            title="footer.policies"
            className={styles['link']}
            onClick={() => navigate('/policies')}
          /> */}
          <Text
            title="footer.security_and_policy"
            className={styles['link']}
            onClick={() => navigate('/policies')}
          />
        </div>
      </div>
      <div className={styles['mobile-con']}></div>
    </div>
  );
};

export default Footer;
