import React from 'react';
import styles from './DownloadButton.module.scss';
import download from '../../../assets/icons/download/download.svg';
import Text from '../text/Text';
import { errorMessage } from '../toast/Toast';

import DOMPurify from 'dompurify';

const DownloadButton = ({
  title,
  className,
  color,
  onClick,
  isLink,
  to,
  downloadUrl,
}) => {
  const buttonClass = `${styles.downloadButton} ${className || ''} ${
    color === 'black' ? styles.black : ''
  }`;

  const handleDownload = () => {
    if (downloadUrl) {
      const link = document.createElement('a');
      link.href = DOMPurify.sanitize(downloadUrl);
      link.setAttribute('download', '');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      errorMessage('no_document_attached');
    }
  };

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else if (downloadUrl) {
      handleDownload();
    } else {
      errorMessage('no_document_attached');
    }
  };

  return isLink ? (
    <a
      href={to || '#'}
      className={buttonClass}
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: 'none' }}
      onClick={!to ? handleClick : () => {}}
    >
      <img className={styles.buttonImage} src={download} alt="Download Icon" />
      <Text variant={'text-regular-16'} className={styles.text} title={title} />
    </a>
  ) : (
    <button onClick={handleClick} className={buttonClass}>
      <img className={styles.buttonImage} src={download} alt="Download Icon" />
      <Text variant={'text-regular-16'} className={styles.text} title={title} />
    </button>
  );
};

export default DownloadButton;
