import React from 'react';
import styles from './Admissions.module.scss';
import { HeadingWithSubtext } from '../../../../components/molecule/headingWithSubtext/HeadingWithSubtext';
import { useSelector } from 'react-redux';
import InnerButton from '../../../../components/atoms/innerButton/innerButton';
import ImageWithTitles from '../../../../components/molecule/imageWIthTitles/ImageWithTitles';
import ContactForm from '../../../../components/organism/contactForm/ContactForm';
import approved from '../../../../assets/icons/admissionResources/approved.svg';
import student from '../../../../assets/icons/admissionResources/student.svg';
import calendar from '../../../../assets/icons/admissionResources/calendar.svg';
import desk from '../../../../assets/icons/admissionResources/desk.svg';
import eng from '../../../../assets/icons/admissionResources/eng.svg';
import temple from '../../../../assets/icons/admissionResources/temple.svg';
import world from '../../../../assets/icons/admissionResources/world.svg';
import List from '../../../../components/molecule/list/List';

const Admissions = ({ data = [], currentLanguage }) => {
  const list = [
    'Major.passport_copy',
    'Major.family_book_copy',
    'Major.id_card_copy',
    'Major.high_school_certificate_copy',
    'Major.personal_photo',
    'Major.criminal_status_certificate',
    'Major.national_service_certificate',
    'Major.english_test_certificate',
    'Major.arabic_test_certificate',
    'Major.mathematics_test_certificate',
  ];
  console.log('dsdsdata', data);
  return (
    <div className={styles['admissions-container']}>
      {/* <HeadingWithSubtext
        variant="small"
        title="common.admissions"
        subtext={
          currentLanguage === 'en'
            ? data.addmisionDocumentsEn
            : data.addmissionDocumentsAr
        }
        className={styles['heading-first']}
        extraText={
          <div className={styles['inner-link']}>
            <div>
              <InnerButton title="ApplyNow.Apply_Now" />
            </div>
          </div>
        }
      /> */}
      <div>
        <HeadingWithSubtext variant="small" title="common.documents" />
        <List
          items={list}
          direction="column"
          style={{ gap: '0px', width: '100%' }}
          itemPaddingStart="20px"
          type="li"
          itemClassName={styles['items']}
        />
      </div>
      <HeadingWithSubtext
        variant="small"
        title="Major.Admission_Requirements"
        subtext={
          currentLanguage === 'en'
            ? data?.admissionRequirementsEn
            : data?.admissionRequirementsAr
        }
        ishtml={true}
        className={styles['heading']}
      />
      <ImageWithTitles
        title="Major.GETTING_A_SCHOLARSHIP_AND_STUDY_WITH_US"
        subtitle="Major.All_Scholarships"
        url="/admissions/scholarships-financial-aid"
      />
      <HeadingWithSubtext
        variant="small"
        title="Major.Admission_Sources"
        grid="grid-7"
        subComponents={[
          {
            svg: student,
            text: 'Admission.Overview.New_Student_Orientation',
          },
          {
            svg: approved,
            text: 'Admission.Overview.Registration',
          },
          // {
          //   svg: desk,
          //   text: 'Admission.Overview.Student_Account_Office',
          // },
          {
            svg: calendar,
            text: 'Admission.Overview.Academic_Calendar',
          },
          // {
          //   svg: eng,
          //   text: 'Admission.Overview.Academic_Advising_Support_Center',
          // },
          {
            svg: temple,
            text: 'Admission.Overview.Academy_Experience',
          },
          {
            svg: world,
            text: 'Admission.Overview.International_Student_Office',
          },
        ]}
        className={styles['heading']}
      />
      <ContactForm degreeType={data.degreeType} />
    </div>
  );
};

export default Admissions;
