import React from 'react';
import styles from './NewsLetter.module.scss';
import { Card, HorizontalCard } from '../../../../../../molecule/card/Card';
import arowDown from '../../../../../../../assets/icons/downArrow.svg';
import Text from '../../../../../../atoms/text/Text';
import Button from '../../../../../../atoms/button/Button';
import { useGetQuery } from '../../../../../../../services/apiCall';
import endpoints from '../../../../../../../api/endpoints';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Loader from '../../../../../../atoms/loader/Loader';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import DOMPurify from 'dompurify';

const NewsLetter = ({ api }) => {
  const isTablet = useMediaQuery({ maxWidth: 1200 });

  const navigate = useNavigate();
  const formatDate = (isoDateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = new Date(isoDateString).toLocaleDateString(
      'en-US',
      options
    );
    console.log('Formatted Date:', formattedDate);
    return formattedDate.replace(/-|\./g, '/');
  };

  const currentLanguage = i18next.language;
  const { t } = useTranslation();
  const apiUrl = api ? api : endpoints.news.getNews;
  const params = ['3', '1', '?IsActive=true'];
  const { data, error, isLoading, refetch } = useGetQuery({
    apiUrl: apiUrl,
    params: params,
  });

  if (isLoading) return <Loader />;
  return (
    <div className={styles['news-container']}>
      <div className={styles.grid}>
        {data && data.items && data.items.length > 0 && (
          <div className={styles['grid-item']}>
            <HorizontalCard
              className={styles['horizontal-card-class-name']}
              imageUrl={`${data?.items[0]?.url}${data?.items[0]?.image ?? data?.items[0]?.newsletterImage}`}
              // title={
              //   currentLanguage === 'en'
              //     ? data?.items[0]?.category_EN
              //     : data?.items[0]?.category_AR
              // }
              date={formatDate(
                data?.items[0]?.newsDate ?? data?.items[0]?.newsletterDate ?? ''
              )}
              subtitle={
                currentLanguage === 'en'
                  ? data?.items[0]?.title_EN
                  : data?.items[0]?.title_AR
              }
              description={
                currentLanguage === 'en'
                  ? data?.items[0]?.newsTextEn
                  : data?.items[0]?.newsTextAr
              }
              imageClass={styles['image-class-name']}
              imageStyle={{
                width: 'min-content',
                borderRadius: '0px',
              }}
              contentsStyle={styles['content-class-name']}
            />
          </div>
        )}
        {data && data.items && data.items.length > 1 && !isTablet && (
          <div className={styles['grid-item']}>
            <HorizontalCard
              className={styles['horizontal-card-class-name']}
              imageUrl={`${data?.items[1]?.url}${data?.items[1]?.image ?? data?.items[1]?.newsletterImage}`}
              // title={
              //   currentLanguage === 'en'
              //     ? data?.items[1]?.category_EN
              //     : data?.items[1]?.category_AR
              // }
              date={formatDate(
                data?.items[1]?.newsDate ?? data?.items[1]?.newsletterDate ?? ''
              )}
              subtitle={
                currentLanguage === 'en'
                  ? data?.items[1]?.title_EN
                  : data?.items[1]?.title_AR
              }
              description={
                currentLanguage === 'en'
                  ? data?.items[1]?.newsTextEn
                  : data?.items[1]?.newsTextAr
              }
              imageClass={styles['image-class-name']}
              imageStyle={{
                width: 'min-content',
                borderRadius: '0px',
              }}
              contentsStyle={styles['content-class-name']}
            />
          </div>
        )}
        {data && data.items && data.items.length > 2 && !isTablet && (
          <div className={styles['grid-item']}>
            <HorizontalCard
              className={styles['horizontal-card-class-name']}
              imageUrl={DOMPurify.sanitize(`${data?.items[2]?.url}${data?.items[2]?.image ?? data?.items[2]?.newsletterImage}` )}
              // title={
              //   currentLanguage === 'en'
              //     ? data?.items[2]?.category_EN
              //     : data?.items[2]?.category_AR
              // }
              date={formatDate(
                data?.items[2]?.newsDate ?? data?.items[2]?.newsletterDate ?? ''
              )}
              subtitle={
                currentLanguage === 'en'
                  ? data?.items[2]?.title_EN
                  : data?.items[2]?.title_AR
              }
              description={
                currentLanguage === 'en'
                  ? data?.items[2]?.newsTextEn
                  : data?.items[2]?.newsTextAr
              }
              imageClass={styles['image-class-name']}
              imageStyle={{
                width: 'min-content',
                borderRadius: '0px',
              }}
              contentsStyle={styles['content-class-name']}
            />
          </div>
        )}
      </div>

      <Button
        title={t('home.view_all_the_news')}
        variant="text-regular-20"
        style={{ textTransform: 'uppercase' }}
        styleType="primary"
        className={styles['view-all-button']}
        svg={arowDown}
        svgPosition="right"
        onClick={() => {
          navigate('/news-and-events/news');
        }}
      />
      <div className={styles['view-all']}>
        <Text
          title={'home.view_all_the_news'}
          variant="text-bold-italic-16"
          className={styles['text']}
          svg={arowDown}
          svgPosition="right"
          style={{ gap: '10px' }}
          onClick={() => {
            navigate('/news-and-events/news');
          }}
        />
      </div>
    </div>
  );
};

export default NewsLetter;
