import React from 'react';
import SlideOne from './SlideOne/SlideOne';
import SlideTwo from './SlideTwo/SlideTwo';
import SlideThree from './SlideThree/SlideThree';
import bachelorsImage from './images/Bachelors.jpg';
import phdImage from './images/masterss.jpg';
import mastersImage from './images/phd.jpg';
import styles from '../Degrees.module.scss';

const CarouselData = [
  <>
    <div className={styles.hue}></div>
    <img src={bachelorsImage} alt="" className={styles.bachelorsImage} />
    <SlideOne />
  </>,
  <>
    <div className={styles.hue}></div>
    <img src={mastersImage} alt="" className={styles.bachelorsImage} />
    <SlideTwo />
  </>,
  <>
    <div className={styles.hue}></div>
    <img src={phdImage} alt="" className={styles.bachelorsImage} />
    <SlideThree />
  </>,
];

export { CarouselData };
