import React, { useEffect, useState } from 'react';
import styles from './ParallaxSection.module.scss';
import Text from '../../../../../components/atoms/text/Text';
import defaultImg from '../../../../../assets/images/history/1966/one_main.png';
import defaultInner from '../../../../../assets/images/history/1966/one.png';
import lottiButton from '../../../../../assets/images/history/GIFS/mark.gif';
import lottiSelected from '../../../../../assets/images/history/GIFS/mark_selected.gif';
import { Parallax } from 'react-scroll-parallax';
import Modal from '../../../../../components/hoc/modal/Modal';
import ReactPlayer from 'react-player';
import LazyVideo from '../../../../../components/atoms/lazyVideo/LazyVideo';
import { useMediaQuery } from 'react-responsive';

const ParallaxSection = ({
  id,
  classname,
  currentSection,
  backgroundImage,
  foregroundImage,
  text,
  subtext,
  name,
  sideModal,
  isLottie,
  lottieImage,
  hasVideo,
  backgroundColor,
  isFullScreen,
  innerLaw,
  hideOnMobile,
  mobileImage,
  lottieCLassname,
  onTabletClassname,
  tabletImage,
  isBackdrop,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const isTablet = useMediaQuery({ maxWidth: 1200 });
  let image = isMobile
    ? mobileImage
    : isTablet
      ? (tabletImage ?? backgroundImage)
      : backgroundImage;

  let backgroundStyle = {};
  const [lottiImage, setLottiImage] = useState({
    lotti: lottiButton,
    modalState: true,
  });
  const [isLawOpen, setIsLawOpen] = useState(false);
  if (backgroundColor) {
    backgroundStyle = {
      backgroundColor: backgroundColor,
    };
  } else {
    backgroundStyle = {
      backgroundImage: `url(${image})`,
    };
  }

  const [startAnimation, setStartAnimation] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (currentSection === id && text) {
      setStartAnimation(true);
    }
  }, [currentSection]);

  const textContainerStyle = startAnimation ? styles.animateText : '';

  function over() {
    setLottiImage({ lotti: lottiSelected, modalState: true });
  }
  function out() {
    setLottiImage({ lotti: lottiButton, modalState: false });
  }
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  if (hideOnMobile && isMobile) {
    return;
  }
  console.log('asdasdsadsadas', lottieImage);
  return (
    <div
      className={`${styles['parallax-section']} ${classname ? styles[classname] : ''}`}
      style={backgroundStyle}
    >
      {isBackdrop ? <div className={styles.backdrop}></div> : null}
      <Parallax className={styles.parallacSec} speed={-30}>
        {!isMobile && (!isTablet || !tabletImage) && foregroundImage && (
          <img
            src={foregroundImage ?? defaultInner}
            alt="Foreground"
            className={`${styles['foreground-image']} ${onTabletClassname ? styles[onTabletClassname] : ''}`}
          />
        )}
        {text && (
          <div className={`${styles['text-container']} ${textContainerStyle}`}>
            <Text
              title={text}
              variant="text-bold-italic-30"
              className={styles.text}
            />
            {subtext ? (
              <Text
                title={subtext}
                variant="text-bold-italic-30"
                className={styles.text}
              />
            ) : null}
            {name ? (
              <Text
                title={name}
                variant="text-bold-italic-30"
                className={styles.text}
              />
            ) : null}

            {innerLaw?.text ? (
              <button
                className={styles.button}
                onClick={() => setIsLawOpen(true)}
              >
                <Text
                  title={innerLaw?.text}
                  variant="text-bold-italic-20"
                  // className={styles.text}
                  color="white"
                />
              </button>
            ) : null}
          </div>
        )}
        {isLottie && !isMobile ? (
          <div
            className={`${styles['lotti-container']} ${
              !lottiImage.modalState ? styles.opacity : ''
            } ${styles[lottieCLassname]}`}
            onMouseOver={over}
            onMouseOut={out}
          >
            <img
              src={lottiImage.lotti}
              className={styles.image}
              onClick={
                lottiImage.modalState ? () => setIsModalOpen(true) : () => {}
              }
            />
          </div>
        ) : null}
        {sideModal ? (
          <div
            className={`${styles['side-modal']} ${currentSection === id ? styles['side-modal-visible'] : ''}`}
          >
            <Text
              title={sideModal.text}
              variant="text-bold-italic-20"
              className={styles.text}
              color="black"
            />
            <button
              className={styles.button}
              onClick={() => setIsLawOpen(true)}
            >
              <Text
                title={sideModal.buttonText}
                variant="text-bold-italic-20"
                className={styles.text}
                color="white"
              />
            </button>
          </div>
        ) : null}
        {sideModal?.image ? (
          <Modal
            isOpen={isLawOpen}
            onClose={() => setIsLawOpen(false)}
            modalBodyClassname={styles.modalBody}
          >
            <div style={backgroundStyle}>
              {sideModal?.image && (
                <img
                  src={sideModal.image}
                  alt="Foreground"
                  className={styles['foreground']}
                />
              )}
            </div>
          </Modal>
        ) : null}
        {innerLaw?.image ? (
          <Modal
            isOpen={isLawOpen}
            onClose={() => setIsLawOpen(false)}
            modalBodyClassname={styles.modalBody}
          >
            <div style={backgroundStyle}>
              <img
                src={innerLaw?.image}
                alt="Foreground"
                className={styles['foreground']}
              />
            </div>
          </Modal>
        ) : null}
        {isLottie && !isMobile ? (
          <Modal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            modalBodyClassname={styles.modalBody}
          >
            {lottieImage ? (
              <img
                src={lottieImage}
                alt="Foreground"
                className={styles['lottiImage']}
              />
            ) : (
              <div style={backgroundStyle}>
                {foregroundImage && (
                  <img
                    src={foregroundImage}
                    alt="Foreground"
                    className={styles['foreground']}
                  />
                )}
              </div>
            )}
          </Modal>
        ) : null}
        {hasVideo && !isFullScreen ? (
          <div className={styles['video-container']}>
            <div className={styles['video-wrapper']}>
              <ReactPlayer
                url={hasVideo}
                controls={true}
                playing={currentSection === id}
                loop={false}
                width="100%"
                height="100%"
                config={{
                  file: {
                    attributes: {
                      preload: 'auto',
                    },
                  },
                }}
              />
            </div>
          </div>
        ) : hasVideo && isFullScreen ? (
          currentSection === id - 1 ||
          currentSection === id ||
          currentSection === id + 1 ? (
            <LazyVideo src={hasVideo} isPaused={currentSection !== id} />
          ) : null
        ) : null}
      </Parallax>
    </div>
  );
};

export default ParallaxSection;
