import React, { useState } from 'react';
import styles from './TestimonialCard.module.scss';
import StudentsInfo from '../studentInfo/StudentInfo';
import doubleQuoteLeft from '../../../assets/icons/doubleQuoteLeft.svg';
import doubleQuoteRight from '../../../assets/icons/doubleQuoteRight.svg';
import Modal from '../../hoc/modal/Modal';

const formatDate = (dateString, language) => {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return new Intl.DateTimeFormat(language, options)
    .format(date)
    .replace(/\//g, ' . ');
};

const TestimonialCard = ({
  title,
  description,
  bgImage,
  isTestimonial,
  footerText,
  date,
  currentLanguage,
  isRtl,
  showData,
  reversed,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div
      className={`${styles.TestimonialCard} ${reversed ? styles.reversed : ''}`}
    >
      {bgImage ? (
        <img
          src={bgImage}
          className={`${styles.image} ${reversed ? styles.imageReverse : ''}`}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = '/path-to-fallback-image.jpg';
          }}
        />
      ) : null}
      <div className={`${styles.quote} ${reversed ? styles.quoteReverse : ''}`}>
        <StudentsInfo
          isImageBackground
          doubleQuoteLeft={doubleQuoteLeft}
          doubleQuoteRight={doubleQuoteRight}
          title={title}
          description={description}
          containerClass={styles.student}
          isTestimonial={isTestimonial}
          footerText={footerText}
          date={date ? formatDate(date, currentLanguage) : ''}
          isRtl={isRtl}
          showData={showData}
          onReadMoreClick={openModal}
          limited
        />
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        modalBodyClassname={styles.modalBody}
        dialogClassName={styles.dialogClassName}
      >
        <StudentsInfo
          isImageBackground
          doubleQuoteLeft={doubleQuoteLeft}
          doubleQuoteRight={doubleQuoteRight}
          title={title}
          description={description}
          // containerClass={styles.student}
          isTestimonial={isTestimonial}
          footerText={footerText}
          date={date ? formatDate(date, currentLanguage) : ''}
          isRtl={isRtl}
          showData={showData}
          color="black"
          onClose={closeModal}
          containerClass={styles.testimonialContainer}
          descriptionClass={styles.desc}
          hasClose
          footerClass={styles.footerClass}
          isModal
        />
      </Modal>
    </div>
  );
};

export default TestimonialCard;
