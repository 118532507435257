import React from 'react';
import styles from './CommingSoon.module.scss';
import HeaderSection from '../../organism/headerSection/HeaderSection';
import Footer from '../homePageSections/footer/Footer';
import useScrollToTop from '../../../app/hooks/useScrollToTop';
import Text from '../../atoms/text/Text';

const CommingSoonPage = () => {
  useScrollToTop();

  return (
    <div className={styles.background}>
      <HeaderSection />
      <div className={styles.commingSoonContainer}>
        <div className={styles.subContainer}>
          <Text
            title="common.soon"
            variant="text-bold-80"
            style={{ textTransform: 'uppercase' }}
            className={styles.text}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CommingSoonPage;
