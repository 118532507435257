import React, { useEffect } from 'react';
import HeaderSection from '../../../../components/organism/headerSection/HeaderSection';
import useSmoothScroll from '../../../../app/hooks/useSmoothScroll';
import ParallaxSection from './common/ParallaxSection';
import { parallaxSections, years } from './factory/Data';
import styles from './HistoryNew.module.scss';
import { ParallaxProvider } from 'react-scroll-parallax';
import './style.css';
import Dash from './common/Dash';
import { useMediaQuery } from 'react-responsive';
import Messaging from '../../../../components/atoms/messaging/Messaging';

const HistoryNEW = () => {
  const isTablet = useMediaQuery({ maxWidth: 1200 });

  const unifiedSectionsData = [
    ...parallaxSections.map((section, index) => ({
      component: section.component || ParallaxSection,
      props: { ...section, id: index },
    })),
  ];

  const {
    currentSection,
    sectionRefs,
    setCurrentSectionExternally,
    disableScroll,
    enableScroll,
    prevSection,
  } = useSmoothScroll(unifiedSectionsData);
  // useEffect(() => {
  //   if (currentSection === 26 || currentSection === 27) {
  //     disableScroll();
  //   } else {
  //     enableScroll();
  //   }
  // }, [currentSection]);

  const scrollToSection = (year) => {
    const sectionIndex = unifiedSectionsData.findIndex(
      (section) => section.props.year === year
    );

    if (sectionIndex !== -1 && sectionRefs[sectionIndex]?.current) {
      sectionRefs[sectionIndex].current.scrollIntoView({ behavior: 'smooth' });
      setCurrentSectionExternally(sectionIndex);
    }
  };

  const handleScrollToTop = () => {
    setCurrentSectionExternally(0);
  };

  useEffect(() => {
    if (currentSection === 0 && sectionRefs[0]?.current) {
      sectionRefs[0].current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentSection, sectionRefs]);

  useEffect(() => {
    window.disableScroll = false;
    window.isHoveringCalendar = false;
  }, []);

  const yearView = years.map((number, index) => {
    const isActive =
      number.year === unifiedSectionsData[currentSection]?.props?.year;
    return (
      <Dash
        key={index}
        year={number.year}
        isShort={number.barSize}
        hasVideo={number.hasVideo}
        isActive={isActive}
        onClick={() => scrollToSection(number.year)}
      />
    );
  });

  return (
    <ParallaxProvider>
      {currentSection < 3 ? (
        <HeaderSection fixed={true} isNotAnimated={true} />
      ) : null}
      {currentSection > 2 ? (
        <Messaging
          handleSlideTopSection={handleScrollToTop}
          disableScrollToTop
        />
      ) : null}

      {!isTablet ? (
        <div
          className={`${styles.year_menu} ${currentSection > 2 ? styles['year_menu-visible'] : ''}`}
        >
          {yearView}
        </div>
      ) : (
        <div
          className={`${styles.year_menu_top} ${currentSection > 2 ? styles['year_menu-visible-top'] : ''}`}
        >
          {yearView}
        </div>
      )}
      {unifiedSectionsData.map((section, index) => {
        const Component = section.component;
        const ref = sectionRefs[index];
        return (
          <section
            key={section.id}
            ref={ref}
            className={styles['history-wrapper']}
          >
            <Component
              {...section.props}
              before={currentSection - 1}
              currentSection={currentSection}
              setCurrentSectionExternally={setCurrentSectionExternally}
              disableScroll={disableScroll}
              enableScroll={enableScroll}
              prevSection={prevSection}
            />
          </section>
        );
      })}
    </ParallaxProvider>
  );
};

export default HistoryNEW;
