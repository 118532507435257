import React, { useState, useMemo, useEffect } from 'react';
import styles from './Gallery.module.scss';
import Text from '../../../../../atoms/text/Text';
import { SearchInput } from '../../../../../atoms/searchInput/SearchInput';
import { MediaCard } from '../../../../../molecule/mediaCard/MediaCard';
import Carousel from '../../../../../molecule/carousel/Carousel';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import DOMPurify from 'dompurify';

const Gallery = ({ data, handleSlideChange }) => {
  const { i18n } = useTranslation();
  const isRtl = i18n.language !== 'en';
  const [searchTerm, setSearchTerm] = useState('');
  const [carouselSlides, setCarouselSlides] = useState([]);

  const isLargeDesktop = useMediaQuery({ query: '(min-width: 1200px)' });
  const isDesktop = useMediaQuery({
    query: '(min-width: 992px) and (max-width: 1199px)',
  });
  const isTablet = useMediaQuery({
    query: '(min-width: 768px) and (max-width: 991px)',
  });
  const isPhone = useMediaQuery({ query: '(max-width: 767px)' });

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = useMemo(() => {
    return data?.filter((val) =>
      val.galleryName_EN.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [data, searchTerm]);

  useEffect(() => {
    const getChunkSize = () => {
      if (isLargeDesktop) return 4;
      if (isDesktop) return 3;
      if (isTablet) return 2;
      return 1;
    };

    const chunkSize = getChunkSize();
    const newSlides = [];

    for (let i = 0; i < filteredData?.length; i += chunkSize) {
      newSlides.push(filteredData?.slice(i, i + chunkSize));
    }

    setCarouselSlides(newSlides);
  }, [filteredData, isLargeDesktop, isDesktop, isTablet, isPhone]);
  return (
    <div className={styles.gallery}>
      <div className={styles.head}>
        <Text
          title="bottomNavigation.MediaGallery"
          variant="text-bold-italic-60"
          className={styles.text}
        />
        <SearchInput
          inputClassname={styles.inputStyle}
          hasClear
          color="white"
          onChange={handleSearchChange}
          value={searchTerm}
        />
      </div>
      <div className={styles['carousel-container']}>
        <Carousel
          hasDots={false}
          style={{ minHeight: '100px', height: 'auto' }}
          hasArrows
          components={carouselSlides?.map((slideGroup, index) => (
            <div key={index} className={styles['media-grid']}>
              {slideGroup.map((val, idx) => (
                <MediaCard
                  key={idx}
                  title={isRtl ? val.galleryName_AR : val.galleryName_EN}
                  count={`${val.images.length} ${isRtl ? 'صورة' : 'Pictures'}`}
                  src={DOMPurify.sanitize(val.images[0]?.url + val.images[0]?.imageURL)}
                  onClick={() => handleSlideChange(1, val.mediaGalleryId)}
                />
              ))}
            </div>
          ))}
        />
      </div>
    </div>
  );
};

export default Gallery;
