import React, { useState } from 'react';
import styles from './Navigator.module.scss';
import NavigationMobile from './mobile/NavigationMobile';
import { Menu, Navigation } from './factory/Factory';
import { useMediaQuery } from 'react-responsive';

const Navigator = ({ data, isRtl }) => {
  const [hoveredItem, setHoveredItem] = useState(data[0]);
  const isTablet = useMediaQuery({ maxWidth: 1000 });

  const handleMouseEnter = (index) => {
    setHoveredItem(data[index]);
  };

  const containerClass = isRtl ? `${styles.nav} rtl` : styles.nav;

  return (
    <nav>
      {isTablet ? (
        <div className={styles['mobile-nav']}>
          <NavigationMobile data={data} isRtl={isRtl} />
        </div>
      ) : (
        <div className={containerClass}>
          <div className={styles.container}>
            <Menu
              className={styles.menu}
              data={data}
              onMouseEnter={handleMouseEnter}
              isRtl={isRtl}
            />
            {hoveredItem && <Navigation data={hoveredItem?.children} />}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navigator;
