import React from 'react';
import styles from './Testimonial.module.scss';
import VerticalSlider from '../../../molecule/verticalSlider/VerticalSlider';
import Slide from './testimonialCarousel/slideOne/Slide';
import { useTranslation } from 'react-i18next';
import endpoints from '../../../../api/endpoints';
import { useGetQuery } from '../../../../services/apiCall';
import students from '../../../../assets/images/testimonial.jpg';
import { sanitizeAndValidateUrl } from '../../../../app/helpers/sanitizeAndValidateUrl';

import DOMPurify from 'dompurify';

const SECTION_ID = 8;

const formatDate = (dateString, language) => {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return new Intl.DateTimeFormat(language, options)
    .format(date)
    .replace(/\//g, ' . ');
};
const Testimonials = ({ currentSection }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const apiUrl = endpoints.testimonials.get;
  const loadSlider =
    currentSection >= SECTION_ID - 1 && currentSection <= SECTION_ID + 1;

  const { data, error, isLoading, refetch } = useGetQuery({
    apiUrl: apiUrl,
    params: ['HomePage'],
  });

  const slideComponents = data?.map((item, index) => {
    const sanitizedImage = sanitizeAndValidateUrl(
      `${item.url}${item.testimonialImage}`
    );
    const sanitizedAvatarImage = sanitizeAndValidateUrl(
      `${item.url}${item.testimonialImage}`
    );

    return (
      <Slide
        key={index}
        image={sanitizedImage}
        avatarImage={sanitizedAvatarImage}
        description={
          currentLanguage === 'en' ? item.testimonial_En : item.testimonial_AR
        }
        title={currentLanguage === 'en' ? item.fullName_EN : item.fullName_AR}
        date={currentLanguage === 'en' ? item.messageEN : item.messageAR}
        dateTime={formatDate(
          item.testimonialDate,
          currentLanguage === 'en' ? 'en-US' : 'ar-EG'
        )}
        isTestimonial={item.isTestimonial}
        hasBackdrop
      />
    );
  });
  const defaultSlide = <Slide image={students} />;
  const avatarImages = data?.map((item) => ({
    avatarImage: DOMPurify.sanitize(`${item.url}${item.testimonialImage}`),
    description: currentLanguage === 'en' ? item.fullName_EN : item.fullName_AR,
  }));

  return (
    <div className={styles['testimonial-container']}>
      {loadSlider && (
        <VerticalSlider
          slides={avatarImages}
          components={slideComponents ?? [defaultSlide]}
          titleClassname={styles.titleClass}
        />
      )}
    </div>
  );
};

export default Testimonials;
